export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR_DISMISS = 'LOGIN_ERROR_DISMISS';
export const REMOVE_USER_MEMBERSHIP = 'REMOVE_USER_MEMBERSHIP';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR_DISMISS = 'REGISTER_ERROR_DISMISS';

export const FORGET_PASSWORD_START = 'FORGET_PASSWORD_START';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const AUTH_END = 'AUTH_END';

export const PET_REGISTER_GENE = 'PET_REGISTER_GENE';
export const PET_REGISTER_ID_CODE = 'PET_REGISTER_ID_CODE';
export const PET_REGISTER_NAME_AND_IMAGE = 'PET_REGISTER_NAME_AND_IMAGE';
export const PET_REGISTER_ADDR_AND_BREED = 'PET_REGISTER_ADDR_AND_BREED';
export const PET_REGISTER_AGE_WEIGHT_NEUTERED = 'PET_REGISTER_AGE_WEIGHT_NEUTERED';
export const PET_REGISTER_MEDICALCONDITION_TEMPERAMENT = 'PET_REGISTER_MEDICALCONDITION_TEMPERAMENT';
export const PET_REGISTER_OWENRNAMES = 'PET_REGISTER_OWENRNAMES';
export const PET_REGISTER_INIT = 'PET_REGISTER_INIT';

export const PET_FETCH_ALL = 'PET_FETCH_ALL';
export const PET_FETCH_START = 'PET_FETCH_START';
export const PET_CREATE_START = 'PET_CREATE_START';
export const PET_CREATE_SUCCESS = 'PET_CREATE_SUCCESS';
export const PET_CREATE_ERROR = 'PET_CREATE_ERROR';
export const PET_UPDATE_START = 'PET_UPDATE_START';
export const PET_UPDATE_SUCCESS = 'PET_UPDATE_SUCCESS';
export const PET_UPDATE_ERROR = 'PET_UPDATE_ERROR';
export const PET_REMOVE = 'PET_REMOVE';
export const PET_MEMBERSHIP = 'PET_MEMBERSHIP';
export const PET_DELETE = 'PET_DELETE';

export const CONTACT_CREATE = 'CONTACT_CREATE'; 


export const PET_FLOW_INFORMATION = 'PET_FLOW_INFORMATION'; 

